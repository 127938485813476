<div class="bg-image"></div>

<div class="wrapper sticky">
  <nav class="navbar-fixed-top">
    <a href="#" class="logo">
      <mat-icon>import_contacts</mat-icon> Book Review App
    </a>
    <ul>
      <li><a (click)="loginAction()">Login</a></li>
      <li><a (click)="signupAction()">Signup</a></li>
    </ul>
  </nav>
</div>
<div class="footer" id="signup">
  <h2>Book Review App</h2>
</div>