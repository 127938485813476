<mat-toolbar color="primary">
    <mat-toolbar-row fxLayout="row">
        <span class="title-center">Login</span>
    </mat-toolbar-row>
</mat-toolbar>

<mat-dialog-content class="mat-typography">
    <form [formGroup]="loginForm">
        <div fxFlex fxLayout="column">
            <mat-form-field appearance="fill" fxFlex>
                <mat-label>Username</mat-label>
                <input matInput formControlName="username" required>
                <mat-error *ngIf="loginForm.controls.username.touched && loginForm.controls.username.invalid">
                    <span *ngIf="loginForm.controls.username.errors.required">This field is mandatory</span>
                    <span *ngIf="loginForm.controls.username.errors.pattern">This field is invalid</span>
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="fill" fxFlex>
                <mat-label>Password</mat-label>
                <input matInput formControlName="password" type="password" required>
                <mat-error *ngIf="loginForm.controls.password.touched && loginForm.controls.password.invalid">
                    <span *ngIf="loginForm.controls.password.errors.required">This field is mandatory</span>
                    <span *ngIf="loginForm.controls.password.errors.pattern">This field is invalid</span>
                </mat-error>
            </mat-form-field>
        </div>
    </form>
</mat-dialog-content>

<mat-dialog-actions align="center">
    <button mat-raised-button color="primary" type="submit" (click)="handleSubmit()"
        [disabled]="!(loginForm.valid && loginForm.dirty)">Login</button>
    <button mat-raised-button color="primary" mat-dialog-close>Close</button>
</mat-dialog-actions>